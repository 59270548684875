<template>
  <div class="check-list-status">
    <span class="icon" :class="{ done: check }">
      <i class="fas" :class="{ 'fa-check': check, 'fa-times': !check }"></i>
    </span>
    <span class="ml-1">
      {{ status }}
    </span>
  </div>
</template>

<script>
export default {
  components: {},
  mixins: [],
  props: {
    status: {
      type: String,
      required: true
    },
    check: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
.check-list-status {
  display: flex;
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $color-danger;
    color: $color-danger;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    font-size: 12px;

    &.done {
      border: none;
      background: $color-success;
      color: white;
    }
  }
}
</style>
